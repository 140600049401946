<template>
  <PerfectScrollbar class="v-perfect-scrollbar">
    <slot />
  </PerfectScrollbar>
</template>

<script lang="ts">
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'

export default {
  components: {
    PerfectScrollbar
  }
}
</script>

<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css"/>
